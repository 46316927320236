
import { Component, Ref, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { FormBase, FormInput, FormError, FormSubmit, FormPhone } from "@/components/forms";
import { Debounce } from "@/utils/vue-helpers";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormSubmit,
        FormPhone,
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class PageAdminUsersShow extends Vue {
    @Getter("auth/me") account!: CurrentAccount;

    @Action("account/patch") updateAccount!: AccountUpdate;
    @Action("auth/passwordForgot") passwordForgot!: (payload: PasswordForgotPayload) => Promise<{}>;

    @Ref() form!: FormClass;

    @Ref() passwordForm!: FormClass;

    payload: any = null;

    passwordChangeLoading: boolean = false;

    passwordChangeRequested: boolean = false;

    updatesLoading: boolean = false;

    async mounted() {
        this.payload = {
            id: this.account.id,
            email: this.account.email,
            first_name: this.account.first_name,
            last_name: this.account.last_name,
            phone: this.account.phone,
            locale: this.account.locale,
        };
    }

    @Debounce(500)
    private handleInput() {
        this.updatesLoading = true;
        // @ts-ignore
        this.updateAccount(this.payload).then(() => {
            this.updatesLoading = false;
            this.$toast.open({ message: this.$t("views.settings.admin-users.update_success") as string, type: "success", position: "bottom-right" });
        });
    }

    private submitPasswordChange(form: FormClass) {
        this.passwordForgot({ email: this.account.email })
            .then(() => {
                this.passwordChangeRequested = true;
            })
            .catch((errorResponse: ErrorResponse) => (this.$errorResponse = errorResponse))
            .finally(() => form.reset());
    }
}
